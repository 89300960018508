<template>
  <div class="home-menu-nav-container">
    <div class="position-relative mg-t-16 mg-b-28 w-28 h-28" @click="toUserCenter">
      <img :src="$store.state.userInfo.avatar" class="user-img">
      <svg-icon :iconClass="isVip ? 'vip_solo' : 'vip_free'" class="vip-free"></svg-icon>
    </div>
    <div class="home-menu-nav-item" v-for="(menu, index ) in menuList" :key="menu.id" @click="onMenuClick(menu, index)">
      <svg-icon :iconClass="currentIndex === index ? menu.activeClass : menu.class" class="w-20 h-20"
        :title="menu.title"></svg-icon>
    </div>
    <div class="mg-b-38 cursor-pointer" @click="handleOpenSearch" >
        <svg-icon icon-class="search" class="w-20 h-20" title="搜索"></svg-icon>
      </div>
    <div class="bottom-menu">
      <div @click="handleOpenTrash" class="cursor-pointer mg-b-38">
        <svg-icon icon-class="recycle_trush" class="w-20 h-20" title="回收站"></svg-icon>
      </div>
      <div @click="hanldeHiddenMenuBar" class="cursor-pointer">
        <svg-icon icon-class="pull" class="w-20 h-20" title="收起目录"></svg-icon>
      </div>
    </div> 
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      currentIndex: 0,
      menuList: [
        { name: '学习台', id: 1, path: '/home/studyPlatform/cardAll', class: 'studyPlatfrom', activeClass: 'studyPlatfrom_active', title: '学习台' },
        // { name:'机构', id: 2, path:'/home/organization',class:'studyPlatfrom' },
        { name: '统计', id: 4, path: '/home/summary', class: 'summary', activeClass: 'summary_active', title: "统计" },
        // { name: '商店', id: 5, path: '/home/shop', class: 'shop', activeClass: 'shop_active', title: '商店' },
      ]
    }
  },
  methods: {
    toUserCenter() {
      this.currentIndex = ''
      this.$router.push('/home/userCenter')
    },
    onMenuClick(menu, index) {
      if (this.currentIndex === index) return
      this.currentIndex = index
      this.$router.push(menu.path)
    },
    hanldeHiddenMenuBar() {
      this.$store.commit('setShowCatelog', !this.$store.state.showCatelog)
    },
    handleOpenSearch() {
      this.$emit('handleOpenSearch')
    },
    handleOpenTrash() {
      this.$emit('handleOpenTrash')
    },
    updateCurrentIndex(route) {
      switch (route.name) {
        case 'Summary':
          this.currentIndex = 1
          break;
        case 'Shop':
          this.currentIndex = 2
          break;
        case 'CardAll':
          this.currentIndex = 0
          break;
        case 'UserCenter':
          this.currentIndex = ''
          break;
        case 'VIP':
          this.currentIndex = ''
          break;
      }
    },
  },
  computed: {
    ...mapGetters(['isVip'])
  },
  watch: {
    $route(newRoute) {
      this.updateCurrentIndex(newRoute)
    }
  },
  created() {
    this.updateCurrentIndex(this.$route)
  }
}
</script>

<style lang="scss" scoped>
.home-menu-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 54px;
  // border-right: 1px solid rgba(247, 246, 250, .7);

  .user-img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }
  }

  .vip-free {
    position: absolute;
    right: -3px;
    bottom: 0;
    width: 14px;
    height: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  .home-menu-nav-item {
    margin-bottom: 35px;
    width: 20px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .bottom-menu {
    position: absolute;
    bottom: 30px;
  }
}
</style>
