<template>
  <div>
    <el-dialog :visible.sync="_searchDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      custom-class="my-search-dialog" width="60%">
      <div class="search-content">
        <div class="search-text" v-if="isSearching">正在搜索中...</div>
        <div v-else id="search-scroll-box">
          <div class="search-text" v-if="value === '' && cardList.length === 0">输入关键词开始搜索!</div>
          <div v-else-if="cardList.length > 0">
            <div class="pd-l-12">共筛选出 {{ totalCardNum }} 张卡牌</div>
            <card-list :cardList="cardList" :showColumn="showColumn" :myChapterWidth="myChapterWidth"
              @toEditorCard="editorCard" @toCardPreview="toCardPreview" :enableBatchOption="true"
              @openChapterOption="openChapterOption" :needPadding="false" :needMargin="true" :isFromSearch="true"
              :isLoadData="isLoadData" :page="page">
            </card-list>
          </div>
          <div class="search-text" v-else-if="value !== '' && cardList.length === 0">暂无相关内容的卡牌!</div>
        </div>
      </div>
      <span slot="title" class="el-dialog__title">
        <img src="../../assets/images/icons_action_light_search_24pt@2x.png" class="search-img" />
        <div class="my-select">
          <el-cascader :options="ChapterList" :props="Props" v-model="chapterId" :show-all-levels="false"
            @change="handleSelect" clearable placeholder="选择卡牌盒">
          </el-cascader>
        </div>
        <div class="myInput">
          <el-input v-model="value" placeholder="搜索卡片" @input="seachCard"></el-input>
        </div>
      </span>
    </el-dialog>
    <chapter-options ref="chapterOptions" @selectChapter="onSelectedChapter">
    </chapter-options>
    <card-editor :firstCardModel="firstCardModel" v-if="isShowEditorCard && Object.keys(firstCardModel).length > 0"
      @closeEditor="closeEditor" :isEditorCard="isEditorCard" :currentEditorCard="currentEditorCard"
      @saveEditSuccess="saveEditSuccess" :editorCardList="cardList" :currentCardIndex="currentCardIndex"
      @deleteCardSuccess="deleteCardSuccess" @openImportData="openUpload" :currentChapter="{}"
      :style="{zIndex: searchDialogVisible?'3000':'',position:'fixed'}" :isEditLinkCard="isEditLinkCard"
      :isFromSearch="true">
    </card-editor>
    <card-viewer :firstCardModel="firstCardModel" v-if="isPreview && Object.keys(firstCardModel).length > 0"
      ref="myCardViewer" :studyCardData="studyCardData" @ClosePreview="ClosePreview" :cardList="cardList"
      :currentCardIndex="currentCardIndex" @previewToEditorCard="previewToEditorCard"
      @deleteCardSuccess="deleteCardSuccess" :isShowEditorCard="isShowEditorCard" @openCreateCard="openCreateCard"
      :style="{zIndex: searchDialogVisible?'2999':''}" @editorLinkCard="editorLinkCard">
    </card-viewer>
  </div>
</template>

<script>
import request from '../../libs/api.request'
import CardList from '../CardList.vue'
import { debounce } from '../../libs/analyse'
import ChapterOptions from '../ChapterOptions.vue'
import CardEditor from '../CardEditor.vue'
import CardViewer from '../CardViewer.vue'
import { handleBatchCard } from '../../libs/batch'
export default {
  props: {
    searchDialogVisible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    CardList,
    ChapterOptions,
    CardEditor,
    CardViewer,
  },
  data() {
    return {
      value: '',
      selectChapterList: [{ chapterId: 0, name: '全部卡片' }],
      ChapterList: [],
      Props: {
        label: 'name',
        value: 'chapterId',
        children: 'childList',
        checkStrictly: true
      },
      chapterId: '', // 选择的卡牌盒id
      isSearching: false,
      page: 1,
      size: 100,
      isShowEditorCard: true,
      totalCardNum: '',
      listScrollHeight: '',
      listClientHeight: 552,
      listScrollTop: '',
      hasMoreData: false,
      isLoadData: false,
      batchSelectedCard: [],
      showColumn: 0,
      myChapterWidth: 0,
      ///----
      cardList: [],
      isShowEditorCard: false,
      currentEditorCard: {},
      currentCardIndex: 0,
      isEditorCard: false,
      isPreview: false,
      studyCardData: {},// 当前预览的卡牌数据
      firstCardModel: {},
      timer: null,
      isEditLinkCard: false // 
    }
  },
  methods: {
    modifyChapterList(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childList === null || data[i].childList == undefined || data[i].childList.length < 1) {
          data[i].childList = undefined
        } else {
          this.modifyChapterList(data[i].childList)
        }
      }
      return data
    },
    openUpload() {
      if (this.isShowEditorCard || this.isPreview || this._searchDialogVisible) {
        this.isShowEditorCard = false
        this.isPreview = false
        this._searchDialogVisible = false
      }
      this.$emit('openImportData')
    },
    // 编辑双链卡牌
    editorLinkCard(card, model) {
      this.isEditLinkCard = true
      this.firstCardModel = model
      this.isShowSelectChpater = true
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
    },
    linkToVip() {
      this.$store.commit('changeShowIntroduceVip', false)
      this.$router.push('/home/userCenter/vip')
    },
    closeVipIntroduce() {
      this.$store.commit('changeShowIntroduceVip', false)
    },
    handleOpenSearch() {
      this.searchDialogVisible = true
    },
    // 创建卡牌
    openCreateCard(curCard, cardList) {
      this.currentEditorCard = curCard
      this.cardList = cardList
      if (this.isPreview || this.isStudy) {
        this.isShowSelectChpater = true
      }
      this.isShowEditorCard = true
      console.log(this.$store.state.selectedModel);
      if (Object.keys(this.$store.state.selectedModel).length > 0) {
        this.firstCardModel = this.$store.state.selectedModel
      } else {
        this.firstCardModel = this.$store.state.modelList[0]
      }
    },
    async getCardModel(modelId) {
     const res = await request.request({
        url: "model/detail",
        params: {
          modelId
        }
      })
      this.firstCardModel = res
    },
    // 进入编辑卡牌
    async editorCard(card, index) {
      await this.getCardModel(card.modelId)
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.currentCardIndex = index
    },
    // 进入编辑卡牌或者从预览到卡牌编辑
    previewToEditorCard(card, index, model) {
      console.log('edit');
      if (this.isPreview) {
        this.firstCardModel = model
      } else {
        this.getCardModel(card.modelId)
      }
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.currentCardIndex = index
    },
    // 卡牌预览
    async toCardPreview(card, index, cardList) {
      await this.getCardModel(card.modelId)
      this.isPreview = true
      this.studyCardData = card
      this.currentCardIndex = index
      this.cardList = cardList
    },
    closeEditor(hasSave, hasDeleteCard, hasMove) {
      if (hasMove) {
        location.reload()
        return
      }
      this.isShowEditorCard = false
      this.isEditorCard = false
      this.isEditLinkCard = false
      if (!this.isPreview) {
        this.firstCardModel = {}
      }
    },
    // 退出预览
    ClosePreview() {
      this.isPreview = false
    },
    saveEditSuccess(card, index) {
      if (this.isPreview) {
        this.closeEditor()
        this.$refs.myCardViewer.rendHtml()
      }
      this.cardList.splice(index, 1, card)
    },
    deleteCardSuccess(index) {
      this.cardList.splice(index, 1)
    },
    // ---
    openChapterOption(batchSelectedCard) {
      // 批量选中的卡牌
      this.batchSelectedCard = batchSelectedCard
      this.$refs.chapterOptions.open();
    },
    // 批量移动
    onSelectedChapter(chapter) {
      handleBatchCard(this, `将所选卡牌移动到【 ${chapter.name} 】?`, '移动', this.$refs.chapterOptions, {
        targetChapterId: chapter.chapterId,
        cardIds: this.batchSelectedCard
      },
        '/card/batchMove')
    },
    handleSelect(value) {
      if (value.length !== 0) {
        if(value[value.length-1] == 1001){
          this.chapterId = 0
        }else {
          this.chapterId = value[value.length - 1]
        }
      } else {
        this.chapterId = 0
      }
      this.page = 1
      this.getCardList()
    },
    loadMoreData() {
      if (this.hasMoreData || this.isLoadData) {
        return
      }
      this.page++
      this.getmoreCardList()
    },
    seachCard: debounce(function () {
      // if (this.value.trim() === '') {
      //   return
      // }
      this.page = 1
      this.getCardList()
    }, 200),
    getCardList() {
      this.isSearching = true
      this.handleResize()
      request.request({
        url: '/card/all',
        params: {
          chapterId: this.chapterId || 0,
          keyword: this.value,
          page: this.page,
          size: this.size,
        }
      }).then( res => {
        this.cardList = res.list
        this.isSearching = false
        this.totalCardNum = res.list.length < res.totalCardNum ?res.list.length : res.totalCardNum
        this.hasMoreData = res.list.length < this.size
      })
    },
    getmoreCardList() {
      this.isLoadData = true
      request.request({
        url: '/card/all',
        params: {
          chapterId: this.chapterId || 0,
          keyword: this.value,
          page: this.page,
          size: this.size,
        }
      }).then(res => {
        if (this.page === 1) {
          this.cardList = res.list
          this.totalCardNum = res.totalCardNum
        } else {
          this.cardList = this.cardList.concat(res.list)
        }
        this.hasMoreData = res.list.length < this.size
        this.isLoadData = false
      })
    },
    handleLoadData() {
      if (this.listClientHeight + this.getScrollTop() >= this.getScrollHeight() - 150) {
        this.loadMoreData()
      }
    },
    // 获取文档总高度
    getScrollHeight() {
      if (document.getElementById('search-scroll-box')) {
        let listScrollHeight = document.getElementById('search-scroll-box').scrollHeight
        return listScrollHeight
      }
    },
    // 获取滚动高度
    getScrollTop() {
      if (document.getElementsByClassName('search-content')) {
        let listScrollTop = document.getElementsByClassName('search-content')[0].scrollTop
        return listScrollTop
      }
    },
    handleResize() {
      if (document.querySelector('.search-content')) {
        const searchContentWidth = document.querySelector('.search-content').clientWidth
        this.myChapterWidth = searchContentWidth - 24
        this.showColumn = Math.round(this.myChapterWidth / 260)
      }
    }
  },
  computed: {
    _searchDialogVisible: {
      get() {
        return this.searchDialogVisible
      },
      set(v) {
        this.$emit('changeSearchDialogVisible', v)
      }
    }
  },
  created() {
    this.selectChapterList = this.selectChapterList.concat(this.$store.state.allChapterList)
  },
  mounted() {
    this.chapterId = this.selectChapterList[0].chapterId
    window.addEventListener('scroll', this.handleLoadData, true)
    window.addEventListener('resize', this.handleResize, true)
    setTimeout(() => {
      this.handleResize()
    }, 200)
    if (this.$store.state.enableChapterList != null) {
      this.ChapterList = this.modifyChapterList(this.$store.state.enableChapterList)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleLoadData, true)
    window.removeEventListener('resize', this.handleResize, true)

  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/mixin.scss";

.search-content {
  height: 100%;
  padding-bottom: 200px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  overflow-y: auto;

  .search-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #202020;
  }

}

::v-deep .my-search-dialog .el-dialog__body {
  height: 100%;
  box-sizing: border-box;
  padding: 12px;
  padding-left: 0px;
  padding-right: 0px;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

::v-deep .my-search-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  margin-top: 0 !important;
  height: 80%;
  overflow: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.myInput {
  width: 100%;

  ::v-deep .el-input__inner {
    padding-left: 16px;
    padding-right: 38px;
    height: 100%;
    border: none;
    font-size: 16px;
    color: #202020;

    &::placeholder {
      font-size: 14px;
      color: rgba(32, 32, 32, 0.4);
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      font-size: 14px;
      color: rgba(32, 32, 32, 0.4);
    }
  }
}

.my-select {
  ::v-deep .el-input__inner {
    text-align: center;
    border-radius: 8px;
    height: 32px;
    line-height: 32px;
    background: #f7f7f7;
  }
  ::v-deep .el-input__icon {
    height: 32px;
    line-height: 32px;
  }
}

::v-deep .el-cascader {
  width: 200px;
}

::v-deep .el-dialog__header {
  padding: 16px 24px 16px;
  height: 64px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.06);
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 22px;
  top: 10px;
}

::v-deep .el-dialog__headerbtn {
  right: 24px;
  top: 21px;
}

::v-deep .el-dialog__title {
  display: flex;
  align-items: center;

  .search-img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

}

::v-deep .el-select {
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    font-size: 14px;
    color: #202020;
    border: none;
    background-color: #f7f7f7;
  }

  .el-input__icon {
    line-height: 32px;
  }
}

::v-deep .el-scrollbar {
  .el-scrollbar__bar {
    display: none;
    width: 0 !important;
  }
}

::v-deep .el-cascader-menu {
  max-width: 250px;
}
</style>
<style>
.myselect {
  max-width: 400px !important;
  min-width: max-content !important;
  z-index: 9999 !important;
}

.myselect .el-select-dropdown__item {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  line-height: 48px;
  padding: 0 12px 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.myselect .el-scrollbar__bar {
  width: 0 !important;
}
</style>