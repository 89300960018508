<template>
  <div>
    <VipIntroduceBox title="成为会员，解锁所有功能" @submitClick="submitClick" @closeClick="closeClick">
      <div class="privilege-box">
        <div class="item-box" v-for="item in privilegeList" :key="item.id">
          <div class="item">
            <img :src="item.url" >
          </div>
          <div class="text">{{item.name}}</div>
        </div>
      </div>
    </VipIntroduceBox>
  </div>
</template>
<script>
import VipIntroduceBox from "./VipIntroduceBox.vue"
export default ({
  components: {
    VipIntroduceBox
  },
  data() {
    return {
      privilegeList: [
        {
          name: '语音朗读',
          url: require('../../assets/images/vip_voice.png'),
          id: 1
        },
        {
          name: '无限图片',
          url: require('../../assets/images/vip_image.png'),
          id: 2
        }, {
          name: '无限卡牌盒',
          url: require('../../assets/images/vip_card.png'),
          id: 3
        }, {
          name: '无限单词卡',
          url: require('../../assets/images/vip_card_word.png'),
          id: 4
        }, {
          name: '无限双链',
          url: require('../../assets/images/vip_study.png'),
          id: 5
        }, {
          name: '回收站',
          url: require('../../assets/images/vip_history.png'),
          id: 6
        },
      ]
    }
  },
  methods: {
    submitClick() {
      this.$emit('linkToVip')
    },
    closeClick() {
      this.$emit('closeClick')
    }
  },
})
</script>

<style lang="scss" scoped>
.privilege-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 125px;

  .item-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-child(2) {
      margin: 0 28px;
    }

    &:nth-child(5) {
      margin: 0 28px;
    }

    .item {
      background-color: #fff;
      border-radius: 12px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .text {
      margin: 12px 0 24px;
      font-size: 14px;
      color: rgba(0, 0, 10, 0.8000);
      line-height: 20px;
    }
  }
}
</style>