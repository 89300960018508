<template>
  <div class="home">
    <div class="home-menu">
      <home-menu-nav-bar @handleOpenSearch="handleOpenSearch" @handleOpenTrash="handleOpenTrash"></home-menu-nav-bar>
    </div>
    <div class="home-right">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <card-search v-if="searchDialogVisible" :searchDialogVisible.sync="searchDialogVisible"
      @changeSearchDialogVisible="changeSearchDialogVisible"  ref="cardSearch" @openImportData="openUpload" >
    </card-search>
    <card-editor :firstCardModel="firstCardModel" v-if="isShowEditorCard && Object.keys(firstCardModel).length > 0"
      @closeEditor="closeEditor" :isEditorCard="isEditorCard" :currentEditorCard="currentEditorCard"
      @saveEditSuccess="saveEditSuccess" :editorCardList="cardList" :currentCardIndex="currentCardIndex"
      @deleteCardSuccess="deleteCardSuccess" @openImportData="openUpload" :currentChapter="{}" :style="{zIndex: searchDialogVisible?'3000':''}" :isEditLinkCard="isEditLinkCard">
    </card-editor>
    <card-viewer :firstCardModel="firstCardModel" v-if="isPreview && Object.keys(firstCardModel).length > 0"
      ref="myCardViewer" :studyCardData="studyCardData" @ClosePreview="ClosePreview" :cardList="cardList"
      :currentCardIndex="currentCardIndex" @previewToEditorCard="previewToEditorCard"
      @deleteCardSuccess="deleteCardSuccess" :isShowEditorCard="isShowEditorCard" @openCreateCard="openCreateCard" :style="{zIndex: searchDialogVisible?'2999':''}" @editorLinkCard="editorLinkCard">
    </card-viewer>
    <import-data ref="importData"></import-data>
    <VipPrivilege v-if="$store.state.showIntroduceVip" @closeClick="closeVipIntroduce" @linkToVip="linkToVip"></VipPrivilege>
    <RecycleBin v-if="trashVisible" :trashVisible.sync="trashVisible" @updateTrashVisible="updateTrashVisible"></RecycleBin>
  </div>
</template>

<script>
import request from '../libs/api.request'
import { showScrollBar } from '../libs/util'

import HomeMenuNavBar from '../components/HomeMenuNavBar.vue'
import CardSearch from '../components/common/CardSearch.vue'
import CardEditor from '../components/CardEditor.vue'
import StudyViewer from '../components/StudyViewer.vue'
import CardViewer from '../components/CardViewer.vue'
import ImportData from '../components/ImportData.vue'
import VipPrivilege from '../components/vip/VipPrivilege.vue'
import RecycleBin from '../components/common/RecycleBin.vue'


export default {
  name: 'Home',
  components: {
    HomeMenuNavBar,
    CardSearch,
    CardEditor,
    StudyViewer,
    CardViewer,
    ImportData,
    VipPrivilege,
    RecycleBin
  },
  data() {
    return {
      aliveComponent: 'StudyPlatform',
      searchDialogVisible: false,
      cardList: [],
      isShowEditorCard: false,
      currentEditorCard: {},
      currentCardIndex: 0,
      isEditorCard: false,
      isPreview: false,
      studyCardData: {},// 当前预览的卡牌数据
      firstCardModel: {},
      timer: null,
      isEditLinkCard:false, // 
      trashVisible:false
    }
  },
  methods: {
    handleOpenTrash(){
      this.trashVisible = true
    },
    updateTrashVisible(v){
      this.trashVisible = v 
    },
    // 编辑双链卡牌
    editorLinkCard(card,model) {
      this.isEditLinkCard= true
      this.firstCardModel = model
      this.isShowSelectChpater = true
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
    },
    linkToVip(){
      this.$store.commit('changeShowIntroduceVip',false)
      this.$router.push('/home/userCenter/vip')
    },
    closeVipIntroduce(){
      this.$store.commit('changeShowIntroduceVip',false)
    },
    handleOpenSearch() {
      this.searchDialogVisible = true
    },
    // 创建卡牌
    openCreateCard(curCard, cardList) {
      this.currentEditorCard = curCard
      this.cardList = cardList
      if (this.isPreview || this.isStudy) {
        this.isShowSelectChpater = true
      }
      this.isShowEditorCard = true
      console.log(this.$store.state.selectedModel);
      if(Object.keys(this.$store.state.selectedModel).length > 0) {
        this.firstCardModel = this.$store.state.selectedModel
      }else {
        this.firstCardModel = this.$store.state.modelList[0]
      }
    },
    getCardModel(modelId) {
      request.request({
        url: "model/detail",
        params: {
          modelId
        }
      }).then(res => {
        this.firstCardModel = res
      })
    },
    // 进入编辑卡牌
    editorCard(card, index, cardList) {
      this.getCardModel(card.modelId)
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.currentCardIndex = index
      this.cardList = cardList
    },
    // 进入编辑卡牌或者从预览到卡牌编辑
    previewToEditorCard(card, index, model) {
      console.log('edit');
      if (this.isPreview) {
        this.firstCardModel = model
      } else {
        this.getCardModel(card.modelId)
      }
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.currentCardIndex = index
    },
    // 卡牌预览
    toCardPreview(card, index, cardList) {
      this.getCardModel(card.modelId)
      this.isPreview = true
      this.studyCardData = card
      this.currentCardIndex = index
      this.cardList = cardList
    },
    closeEditor(hasSave, hasDeleteCard, hasMove) {
      if (hasMove) {
        location.reload()
        return
      }
      this.isShowEditorCard = false
      this.isEditorCard = false
      this.isEditLinkCard = false
      if (!this.isPreview) {
        this.firstCardModel = {}
      }
    },
    // 退出预览
    ClosePreview() {
      console.log('tuichuyulan');
      this.isPreview = false
    },
    saveEditSuccess(card, index) {
      if (this.isPreview) {
        this.closeEditor()
        this.$refs.myCardViewer.rendHtml()
      }
      this.cardList.splice(index, 1, card)
      this.$refs.cardSearch.cardList.splice(index, 1, card)
      // console.log(this.$refs.cardSearch);
    },
    deleteCardSuccess(index) {
      this.cardList.splice(index, 1)
    },
    toggleMenu(item) {
      this.aliveComponent = item.component
    },
    openSearch() {
      this.searchDialogVisible = true
      hideScrollBar()
    },
    changeSearchDialogVisible(v) {
      this.searchDialogVisible = v
      showScrollBar()
    },
    openUpload() {
      // if (this.isShowEditorCard || this.isPreview || this.searchDialogVisible) {
      //   this.isShowEditorCard = false
      //   this.isPreview = false
      //   this.searchDialogVisible = false
      // }
      this.timer = setTimeout(() => {
        this.$refs.importData.open();
      }, 200)
    },
  },

  async created() {
    this.$store.dispatch('modifyUserInfo')
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  display: flex;
  position: relative;
  overflow-x: hidden;
  background: #f7f7f7;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }

  .home-menu {
    width: 54px;
  }

  .home-left {
    width: 264px;
    z-index: 99;

    .home-left-container {
      width: 264px;
      height: 100%;
      background-color: #fff;
    }
  }

  .home-right {
    flex: 1;
  }
}
</style>
