<!-- 回收站 -->
<template>
  <div>
    <el-dialog :visible.sync="_trashVisible" append-to-body width="800px" :close-on-click-modal="false" title="更换模版"
      custom-class="model-dialog">
      <div class="mg-t-20" style="padding:12px 18px 60px 34px">
        <template v-if="!isSearch">
          <el-timeline v-show='currentIndex == 0'>
            <el-timeline-item timestamp="" placement="bottom" v-for='(value,key,Index) in cardList' :key="Index">
              <el-collapse v-model="activeName">
                <el-collapse-item :title="key" :name="Index">
                  <el-row :gutter="12">
                    <el-col :span="8" v-for="(card, index) in value" :key="card.recoveryId">
                      <div class="mg-t-12 bg-f bdr-10 h-218 pd-10 cursor-pointer shadow-2 position-relative card-box"
                        style="overflow-y: hidden;" @click="onCardClick(card, index)" :class="[isActive(card)]">
                        <div v-if="card.fld1 !== ''" class="text-ellipsis-2 fs-16 c-0 text-strong mg-b-6"
                          v-html="card.fld1">
                        </div>
                        <div class="c-9 fs-14" :class="card.image === '' ? 'text-ellipsis-3' : 'text-ellipsis-1'"
                          v-html="card.fld2"></div>
                        <img v-if="card.image" class="bdr-6 w-fill mg-t-4 h-80" style="object-fit: cover"
                          :src="card.image" onerror="onImageLoadErr(this)" />
                        <div class="justify-content-space-between fs-12 c-9 position-absolute b-0 l-0 r-0 pd-10">
                          <div class="text-ellipsis-1" style="max-width: 174px">{{ card.chapterName }}</div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </el-collapse>
            </el-timeline-item>
          </el-timeline>
          <el-timeline v-show='currentIndex == 1'>
            <el-timeline-item placement="bottom" v-for="(item,key1,index1) in chapterList" :key="index1">
              <el-collapse v-model="activeName1">
                <el-collapse-item :title="key1" :name="index1">
                  <el-row :gutter="12">
                    <el-col :span="12" v-for="(chapter,index) in item" :key="chapter.recoveryId">
                      <div
                        class="mg-t-12 bg-f bdr-10 h-158 pd-10 cursor-pointer shadow-2 position-relative card-box chapter-box"
                        @click="onChapterClick(chapter, index)" :class="[isActive(chapter)]">
                        <div class="menu-box recover-box" @click.stop="onRecoverClick(chapter)">
                          <svg-icon iconClass="recover" class="w-20 h-20"></svg-icon>
                        </div>
                        <img v-if="chapter.image" class="bdr-6 w-100" style="object-fit: cover" :src="chapter.image"
                          onerror="onImageLoadErr(this)" />
                        <div class="chapter-content">
                          <div class="text-ellipsis-3 fs-16 c-0 text-strong mg-b-8 flex-1">
                            {{chapter.name}}
                          </div>
                          <div class="align-items-center">
                            <el-progress v-if="chapter.status === 1" :show-text="false" class="my-progress"
                              type="circle"
                              :color="chapter.cardNum > 0 && chapter.newCardNum[1] === 0 ? '#FF8800' : '#FFB700'"
                              :stroke-width="2" :width="18"
                              :percentage="chapter.cardNum > 0 && chapter.newCardNum[1] === 0 ? hanldeCompleteNum : studyCompleteNum">
                            </el-progress>
                            <el-progress v-else :show-text="false" color="rgba(123, 123, 128, 0.12)" :stroke-width="2"
                              :width="18" type="circle" class="my-progress"
                              :percentage="chapter.cardNum > 0 && chapter.newCardNum[1] === 0 ? hanldeCompleteNum : studyCompleteNum">
                            </el-progress>
                            <div class="progress-text" :style="{color:'#FF8800'}"
                              v-if="chapter.cardNum>0&& chapter.newCardNum[1]=== 0">
                              已掌握{{chapter.handledCardNum}}/{{ chapter.cardNum }}
                            </div>
                            <div v-else class="progress-text" :style="{color:'#FFB700'}">已学{{ chapter.cardNum -
                            chapter.newCardNum[1]
                            }}/{{ chapter.cardNum }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </el-collapse>
            </el-timeline-item>
          </el-timeline>
        </template>
        <template v-if="isSearch">
          <el-row :gutter="12" v-if="searchCardList.length > 0">
            <el-col :span="8" v-for="(card, index) in searchCardList" :key="card.recoveryId">
              <div class="mg-t-12 bg-f bdr-10 h-218 pd-10 cursor-pointer shadow-2 position-relative card-box"
                style="overflow-y: hidden;" @click="onCardClick(card, index)" :class="[isActive(card)]">
                <div v-if="card.fld1 !== ''" class="text-ellipsis-2 fs-16 c-0 text-strong mg-b-6" v-html="card.fld1">
                </div>
                <div class="c-9 fs-14" :class="card.image === '' ? 'text-ellipsis-3' : 'text-ellipsis-1'"
                  v-html="card.fld2"></div>
                <img v-if="card.image" class="bdr-6 w-fill mg-t-4 h-80" style="object-fit: cover" :src="card.image"
                  onerror="onImageLoadErr(this)" />
                <div class="justify-content-space-between fs-12 c-9 position-absolute b-0 l-0 r-0 pd-10">
                  <div class="text-ellipsis-1" style="max-width: 174px">{{ card.chapterName }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="fs-16 text-align-center position-absolute"
            style="top:50%;left:50%;transform: translate(-50%,-50%);color:rgba(32,32,32,.5)" v-else>
            搜索结果为空
          </div>
        </template>
      </div>
      <div slot="title" class="el-dialog__title">
        <div class="title-tab">
          <div class="tab-item" v-for="(item,index) in tabList" :key="index" @click="onTabClick(item,index)"
            :class="currentIndex === index ? 'active':''">
            {{item.name}}
          </div>
        </div>
        <div class="title-search">
          <img src="../../assets/images/icons_action_light_search_24pt@2x.png" class="search-img" />
          <el-input v-model="keyword" placeholder="搜索卡片" @input="seachCard"></el-input>
        </div>
      </div>
      <div class="fs-16 text-align-center position-absolute"
        style="top:50%;left:50%;transform: translate(-50%,-50%);color:#202020" v-show="!showBatchMenu && !isSearch">
        无删除记录
      </div>
      <div class="batch-box" v-show="showBatchMenu">
        <div class="display-flex justify-content-space-between align-items-center ">
          <el-checkbox @change="onBatchSelectedAllChange" v-model="checked">
            已选 {{batchSelectedCard.length }}
          </el-checkbox>
          <div class="display-flex position-fixed r-24 f-14">
            <div class="c-app-yellow mg-l-30 cursor-pointer fs-14" @click="onDelete">彻底删除</div>
            <div class="c-app-yellow mg-l-30 cursor-pointer fs-14" @click="onRecover"
              v-show="currentIndex === 0 || isSearch">恢复
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <chapter-options ref="chapterOptions" @selectChapter="selectChapter"></chapter-options>
  </div>
</template>
<script>
import { debounce } from '../../libs/analyse'
import request from '../../libs/api.request';
import { showMessage } from '../../libs/message';
import ChapterOptions from '../ChapterOptions.vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    trashVisible: {
      type: Boolean,
      default: false
    },
    // 选中的卡片的模版ID
    selectModelIdList: {
      type: Array,
      default: () => []
    },
  },
  components: {
    ChapterOptions,
  },
  data() {
    return {
      isSearch: false,
      checked: false,
      currentIndex: 0,
      activeName: [0],
      activeName1: [0],
      batchSelectedCard: [],//批量操作选中的卡，如果当前是全选状态则是排除的
      tabList: [
        {
          name: '卡片'
        },
        {
          name: '卡牌盒'
        }
      ],
      searchCardList: [],
      keyword: '',
      cardList: {},
      chapterList: {},
      chapter: {}, // 恢复的单个卡牌盒
    }
  },
  methods: {
    handleRequest(url,repeat, chapter, fn) {
      request.request({
        url,
        method: 'post',
        data: {
          recoveryId: this.batchSelectedCard,
          chapterId: chapter.chapterId,
          repeat
        }
      }).then(res => {
        this.getCardList()
        this.batchSelectedCard = []
        showMessage({ message: '恢复成功', type: 'success' })
        fn()
      }).catch(e => {
        fn()
        showMessage({ message: e.message, type: 'error' })
      })
    },
    selectChapter(chapter) {
      this.$refs.chapterOptions.close()
      let msg = (this.currentIndex === 0 || this.isSearch) ? '卡片恢复中...' : '卡牌盒恢复中...'
      let url = (this.currentIndex === 0 || this.isSearch) ? '/recovery/rollbackCard' : '/recovery/rollbackChapter'
      let loading = this.$loading({ text: msg })
      request.request({
        url,
        method: 'post',
        data: {
          recoveryId: this.chapter.recoveryId || this.batchSelectedCard,
          chapterId: chapter.chapterId
        }
      }).then(res => {
        loading.close()
        this.updateSearchList()
        this.$store.dispatch('modifyTotalChapterData')
        this.$store.dispatch('modifyChapterList')
        this.$store.dispatch('modifyAllChapterList')
        if (this.currentIndex === 0) {
          this.getCardList()
        } else {
          this.getChapterList()
        }
        this.batchSelectedCard = []
        showMessage({ message: '恢复成功', type: 'success' })
      }).catch(e => {
        loading.close()
        if (e.code === 4041) {
          this.$confirm(e.message, {
            confirmButtonText: '替换',
            cancelButtonText: '跳过',
            distinguishCancelAndClose: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.handleRequest(url,'overwrite', chapter, done)
              } else if (action === 'cancel') {
                this.handleRequest(url,'skip', chapter, done)
              } else {
                done()
              }
            }
          })
        }
      })
    },
    // 全选
    onBatchSelectedAllChange(e) {
      console.log(e);
      if (e) {
        if (!this.isSearch) {
          if (this.currentIndex === 0) {
            for (let key in this.cardList) {
              for (let i = 0; i < this.cardList[key].length; i++) {
                if (!this.batchSelectedCard.includes(this.cardList[key][i].recoveryId)) {
                  this.batchSelectedCard.push(this.cardList[key][i].recoveryId)
                }
              }
            }
          } else {
            for (let key in this.chapterList) {
              for (let i = 0; i < this.chapterList[key].length; i++) {
                if (!this.batchSelectedCard.includes(this.chapterList[key][i].recoveryId)) {
                  this.batchSelectedCard.push(this.chapterList[key][i].recoveryId)
                }
              }
            }
          }
        } else {
          // 搜索时
          for (let i = 0; i < this.searchCardList.length; i++) {
            if (!this.batchSelectedCard.includes(this.searchCardList[i].recoveryId)) {
              this.batchSelectedCard.push(this.searchCardList[i].recoveryId)
            }
          }
        }

      } else {
        this.batchSelectedCard = [];
      }
    },

    // 批量恢复 卡片
    onRecover() {
      if (!this.alertMessage()) return
      if (!this.isVip) {
        this.$store.commit('changeShowIntroduceVip', true)
        return showMessage({ message: '回收站功能为VIP专属，请升级', type: 'warning' })
      }
      let loading = this.$loading({ text: '卡片恢复中...' })
      request.request({
        url: '/recovery/rollbackCard',
        method: 'post',
        data: {
          recoveryId: this.batchSelectedCard
        }
      }).then(res => {
        loading.close()
        this.updateSearchList()
        this.getCardList()
        this.batchSelectedCard = []
        showMessage({ message: "恢复成功", type: 'success' })
      }).catch(e => {
        loading.close()
        if (e.code === 404) {
          this.$confirm(e.message, '选择卡牌盒', {
            confirmButtonText: '选择',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.$refs.chapterOptions.open()
                done()
              } else {
                done();
              }
            }
          })
        } else if (e.code === 4041) {
          let context = '', cantext = ''
          if (this.batchSelectedCard.length > 1) {
            context = '替换'
            cantext = '跳过'
          } else {
            context = '是'
            cantext = '否'
          }
          this.$confirm(e.message, {
            confirmButtonText: context,
            cancelButtonText: cantext,
            distinguishCancelAndClose: this.batchSelectedCard.length > 1 ? true : false,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                // this.handleRequest('/recovery/rollbackCard','overwrite',null,done)
                request.request({
                  url: '/recovery/rollbackCard',
                  method: 'post',
                  data: {
                    recoveryId: this.batchSelectedCard,
                    repeat: 'overwrite'
                  }
                }).then(res => {
                  this.getCardList()
                  this.batchSelectedCard = []
                  showMessage({ message: '恢复成功', type: 'success' })
                  done()
                }).catch(e => {
                  showMessage({ message: e.message, type: 'error' })
                })
              } else if (action === 'cancel') {
                if (this.batchSelectedCard.length > 1) {
                  // this.handleRequest('/recovery/rollbackCard','skip',null,done)
                  request.request({
                    url: '/recovery/rollbackCard',
                    method: 'post',
                    data: {
                      recoveryId: this.batchSelectedCard,
                      repeat: 'skip'
                    }
                  }).then(res => {
                    this.getCardList()
                    this.batchSelectedCard = []
                    showMessage({ message: '恢复成功', type: 'success' })
                    done()
                  }).catch(e => {
                    showMessage({ message: e.message, type: 'error' })
                  })
                }else {
                  done()
                }
              } else {
                done();
              }
            }
          })
        }
      })
    },
    // 批量删除
    onDelete() {
      if (!this.alertMessage()) return
      let msg = (this.currentIndex === 0 || this.isSearch) ? '卡片' : '卡牌盒'
      let url = (this.currentIndex === 0 || this.isSearch) ? '/recovery/deleteCard' : '/recovery/deleteChapter'
      this.$confirm(`${msg}彻底删除后将无法找回，是否彻底删除？`, '确定删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request.request({
          url,
          method: 'post',
          data: {
            recoveryId: this.batchSelectedCard
          }
        }).then(res => {
          this.updateSearchList()
          if (this.currentIndex === 0) {
            this.getCardList()
          } else {
            this.getChapterList()
          }
          this.batchSelectedCard = []
          showMessage({ message: '删除成功', type: 'success' })
        }).catch(e => {
          showMessage({ message: e.message, type: 'error' })
        })
      })
    },
    // 恢复单个卡牌盒
    onRecoverClick(chapter) {
      if (!this.isVip) {
        this.$store.commit('changeShowIntroduceVip', true)
        return showMessage({ message: '回收站功能为VIP专属，请升级', type: 'warning' })
      }
      this.chapter = chapter
      let loading = this.$loading({ text: '卡牌盒恢复中...' })
      request.request({
        url: '/recovery/rollbackChapter',
        method: 'post',
        data: {
          recoveryId: chapter.recoveryId
        }
      }).then(res => {
        this.getChapterList()
        this.$store.dispatch('modifyTotalChapterData')
        this.$store.dispatch('modifyChapterList')
        this.$store.dispatch('modifyAllChapterList')
        loading.close()
        showMessage({ message: '恢复成功', type: 'success' })
      }).catch(e => {
        loading.close()
        if (e.code === 404) {
          this.$confirm(e.message, '选择卡牌盒', {
            confirmButtonText: '选择',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.$refs.chapterOptions.open()
                done()
              } else {
                done();
              }
            }
          })
        } else if (e.code === 4041) {
          this.$confirm(e.message, {
            confirmButtonText: '是',
            cancelButtonText: '否',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                done()
                let loading = this.$loading({ text: '卡牌盒恢复中...' })
                request.request({
                  url: '/recovery/rollbackChapter',
                  method: 'post',
                  data: {
                    recoveryId: chapter.recoveryId,
                    repeat: 'overwrite'
                  }
                }).then(res => {
                  loading.close()
                  showMessage({ message: '恢复成功', type: 'success' })
                  this.batchSelectedCard = []
                  this.getChapterList()
                }).catch(e => {
                  loading.close()
                  showMessage({ message: e.message, type: 'error' })
                })
              } else {
                done();
              }
            }
          })
        } else {
          showMessage({ message: e.message, type: 'error' })
        }
      })
    },
    onChapterClick(chapter) {
      this.onCardClick(chapter)
    },
    onCardClick(card) {
      let indexOf = this.batchSelectedCard.indexOf(card.recoveryId);
      if (indexOf > -1) {
        this.batchSelectedCard.splice(indexOf, 1);
      } else {
        this.batchSelectedCard.push(card.recoveryId);
      }
    },
    seachCard: debounce(function () {
      if (this.keyword.trim().length > 0) {
        this.currentIndex = 0
        this.isSearch = true
        request.request({
          url: '/recovery/allCard',
          params: {
            keyword: this.keyword
          }
        }).then(res => {
          this.searchCardList = []
          this.batchSelectedCard = []
          for (let key in res.dateList) {
            for (let i = 0; i < res.dateList[key].length; i++) {
              this.searchCardList.push(res.dateList[key][i])
            }
          }
        })
      } else {
        this.batchSelectedCard = []
        this.searchCardList = []
        this.isSearch = false
      }
    }, 200),
    alertMessage() {
      if (this.batchSelectedCard.length === 0) {
        showMessage({ message: this.currentIndex === 0 ? '请选择要操作的卡片' : '请选择要操作的卡牌盒', type: 'warning' })
        return false
      } else {
        return true
      }
    },
    onTabClick(item, index) {
      if (!this.isSearch) {
        this.currentIndex = index
        this.batchSelectedCard = []
        this.checked = false
        this.chapter = {}
      }
    },
    getCardList() {
      request.request({
        url: '/recovery/allCard',
      }).then(res => {
        this.cardList = res.dateList
      })
    },
    getChapterList() {
      request.request({
        url: '/recovery/allChapter',
      }).then(res => {
        this.chapterList = res.dateList
      })
    },
    updateSearchList() {
      if (this.isSearch) {
        // 将搜索到的从列表中移除
        for (let i = 0; i < this.batchSelectedCard.length; i++) {
          for (let j = 0; j < this.searchCardList.length; j++) {
            if (this.batchSelectedCard[i] === this.searchCardList[j].recoveryId) {
              this.searchCardList.splice(j, 1)
            }
          }
        }
      }
    },
  },
  computed: {
    // 已完成的学习量百分比
    studyCompleteNum() {
      return this.chapter.cardNum > 0 ? Number(((this.chapter.cardNum - this.chapter.newCardNum[1]) / this.chapter.cardNum * 100).toFixed(1)) : 0
    },
    // 已经掌握的百分比量
    hanldeCompleteNum() {
      return this.chapter.cardNum > 0 ? Number((this.chapter.handledCardNum / this.chapter.cardNum * 100).toFixed(1)) : 0
    },
    ...mapGetters(['isVip']),
    isActive() {
      return (card) => {
        return this.batchSelectedCard.indexOf(card.recoveryId) > -1 ? 'active' : ''
      }
    },
    _trashVisible: {
      get() {
        return this.trashVisible
      },
      set(v) {
        this.$emit('updateTrashVisible', v)
      }
    },
    showBatchMenu() {
      if (!this.isSearch) {
        if (this.currentIndex === 0) {
          return Object.keys(this.cardList).length > 0 ? true : false
        } else {
          return Object.keys(this.chapterList).length > 0 ? true : false
        }
      } else {
        return this.searchCardList.length > 0 ? true : false
      }
    }
  },
  watch: {
    batchSelectedCard(v) {
      this.checked = v.length > 0
    }
  },
  mounted() {
    console.log(this.isSearch);
    this.getCardList()
    this.getChapterList()
  },
}
</script>
<style lang="scss" scoped>
@import './recycle/index.scss';

::v-deep .el-collapse-item__header {
  font-size: 16px !important;
}
::v-deep .el-collapse-item__wrap {
  overflow: inherit;
}
</style>